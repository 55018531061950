import styled from 'styled-components';
import {  Modal, Col, Avatar, Button } from 'antd';
import { couleurs } from 'assets/styles/theme';
  


export const SDialog = styled(Modal)`
.ant-modal-content {
  border-radius: 6px;
  .ant-modal-header {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #ddd;
      .ant-modal-title {        
          font-weight: 500;
          color: ${couleurs.principale};
          font-size: 26px;
          margin-top: 10px  ;
      }
  }
}
`;




export const SDialogErreur = styled(Modal)`
padding:0;
.ant-modal-content {
  border-radius: 6px;
  .ant-modal-body{
    padding:0;
  }
  .ant-modal-footer{
    display:none;
  }
}
`;

export const SDialogErreurEntete = styled(Col)`
background-color:#333;
color:orange;
padding:60px 10px;
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
  .icone{ 
    margin:auto; 
    text-align:center;
    width:70px  ;  
    font-size: 70px;
    line-height:30px;
    padding:0;  
  }
  .titre{ 
    margin:auto; 
    font-weight: 400;
    font-size: 30px;
    text-align:center;
    .anticon svg{
      width:36px;  
    };
  }
`;

export const SDialogErreurCorps = styled(Col)`
font-weight: 500;
font-size: 18px;
padding: 20px;
.titre{
color:orange;
font-size: 24px;
    font-weight: 300;
}
.message{
  font-size: 18px;
}
.action{
text-align:right;
}
`;

export const  SDialogueErreurAction= styled(Button)`
position:absolute;
bottom:20px; 
right:20px;
color:orange;
border:1px solid orange;
&:hover{
  color:white;
background: orange;
border:1px solid orange;
}
`;

export const SEntete = styled(Col)`
font-weight: 500;
font-size: 18px;
margin-bottom: 10px;
`;

export const SCorps = styled(Col)`
font-weight: 500;
font-size: 18px;
margin-bottom: 10px;
`;
export const SAvatar = styled(Avatar)`
  background-color: #fefefe;
  border: 1px solid #DDD;
  margin:0 5px 5px 0;
  svg {
    margin-top:1px;
    fill:${couleurs.principale};
    width: 22px;
  }
`;
