import { IListePagineeDemande, IDemande } from './demande.modele';
import { Page } from 'proxy/commun/representation/Page';
import PaginationMapper from 'core/pagination/pagination.mapper';
import { AnyAction } from 'redux';
import _ from 'lodash';
  
 
const creerListePagineeDemande = (pageDemande:Page<IDemande>):IListePagineeDemande=>{
    const listePagineeDemande:IListePagineeDemande = {} as IListePagineeDemande ;
    listePagineeDemande.liste = pageDemande.content;
    listePagineeDemande.pagination= PaginationMapper.creerPagination(pageDemande);
    return listePagineeDemande;
};

const creerListeNombreDemandeParChampParAnnee = (data:AnyAction[]):any[]=>{
    const map = {};
    data.forEach((item)=>{
        let row = map[item.champ];
        if(!row){
            row={};
            row['champ'] = item.champ ;
        }
        row[item.annee] = item.nombre ;
        map[item.champ] = row;

    });
    return _.values(map);
};
 

const DemandeMapper = {
    creerListePagineeDemande,
    creerListeNombreDemandeParChampParAnnee
};

export default DemandeMapper;
 