import { BellFilled, HomeFilled, UserOutlined } from '@ant-design/icons';
import { Col, Popover, Row, Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import brand from 'assets/images/brand.svg';
import ActionSecondaire from 'composants/action/action.secondaire';
import { useAppSelector } from 'config/store.config';
import AuthService from 'core/auth/auth.service';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import labels from 'util/labelMapping';
import { SHeaderAvecMenu, SUserPopup } from './styles';

const LayoutHeader = () => {
    const user = useAppSelector((state) => state.authState.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(AuthService.logout());
    };

    const toHome = () => {
        navigate('/p');
    };

    return (
        <SHeaderAvecMenu>
            <div className="header">
                <Row wrap={false}>
                    <Col flex="auto" className="logo">
                        <img src={brand} alt="logo" onClick={() => navigate('/')} />
                    </Col>
                    <Col flex="none" className="alerte">
                        <span onClick={toHome}>
                            <Tooltip title="Accueil">
                                <Avatar size={36} icon={<HomeFilled />} className="alerte" />
                            </Tooltip>
                        </span>
                        <Popover content={<span>Aucune notification</span>} title="Notifications" trigger="hover">
                            <Avatar size={36} icon={<BellFilled />} className="alerte" />
                        </Popover>

                        <Popover
                            content={
                                <SUserPopup>
                                    <div className="nomPrenom">{user.firstName + ' ' + user.lastName}</div>
                                    <div className="domain">{user.compagnie ? user.compagnie.libelle : labels.get(user.domaine)}</div>
                                    <ActionSecondaire nom="logout" action={logout} />
                                </SUserPopup>
                            }
                            trigger="hover"
                            placement="bottomRight"
                        >
                            <Avatar size={36} icon={<UserOutlined />} className="user" />
                        </Popover>
                    </Col>
                </Row>
            </div>
        </SHeaderAvecMenu>
    );
};

export default LayoutHeader;
