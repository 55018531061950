import { useAppDispatch, useAppSelector } from 'config/store.config';
import { useCallback, useEffect } from 'react';
import SessionStorage from 'util/session.storage';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from './error.404';
import LoginPage from 'core/auth/auth.ui.login';
import PrivateRoute from './privateRoute';
import AuthService from 'core/auth/auth.service';
import { DOMAINE_ASSURANCE, DOMAINE_ADMIN, DOMAINE_MEDIATEUR } from 'config/domaine.config';
import RouteRoutes from 'domaines/assurance/routes';
import AdminRoutes from 'domaines/admin/routes';
import MediateurRoutes from 'domaines/mediateur/routes';
import { AuthStore } from 'core/auth/auth.store';
import LayoutPriveSansMenu from 'layout/priveSansMenu';
import LayoutPriveAvecMenu from 'layout/priveAvecMenu';
 

const AppRoutes = () => {
    const user = useAppSelector((state) => state.authState.user);
    const dispatch = useAppDispatch();

    useEffect(
        () => {
            if (!user || !user.role) {
                const u = SessionStorage.getUser();
                if (u && u.token) {
                    dispatch(AuthStore.reload());
                } else {
                    dispatch(AuthService.logout());
                }
            }
        },
        [ user, dispatch ]
    );

    const getRoutes = useCallback(
        () => {
            switch (user.domaine) {
            case DOMAINE_ASSURANCE:
                return RouteRoutes();
            case DOMAINE_ADMIN:
                return AdminRoutes();
            case DOMAINE_MEDIATEUR:
                return MediateurRoutes();
            default:
                return [];
            }
        },
        [ user ]
    );

    const getAppLayout = useCallback(
        () => {
            switch (user.domaine) {
            case DOMAINE_ASSURANCE:
                return <LayoutPriveSansMenu/>;
            case DOMAINE_ADMIN:
                return <LayoutPriveAvecMenu/>;
            case DOMAINE_MEDIATEUR:
                return <LayoutPriveSansMenu/>;
            default:
                return [];
            }
        },
        [ user ]
    );

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            {getAppLayout()}
                        </PrivateRoute>
                    }>
                    {getRoutes().map((r) => r)}
                    <Route path="/*" element={<PageNotFound />} />
                </Route>
                <Route path="/*" element={<PageNotFound />} />
                <Route path="/login" element={<LoginPage />} />
            </Routes>
        </BrowserRouter>
    );
};
export default AppRoutes;
