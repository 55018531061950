import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { API_URL } from 'config/constants.config';
import { IChangementMotDePasse, IUser } from './auth.modele';
import { DomaineConfig } from 'config/domaine.config';
import { serviceOptions } from 'util/notificationMiddleWare';

const getAccount = createAsyncThunk('authentication/getAccount', async (jwt:string, {rejectWithValue} ) => {
    const response = await axios.get<any>(API_URL + '/api/account', { headers: { Authorization: 'Bearer ' + jwt } });
    const user = response.data;
    if (user && user.activated) {
        const role = user.authorities[0];
        const domaine = DomaineConfig.roleADomaine(role);
        if (domaine) {
            return { ...user, role, domaine, token: jwt, creationTime: Date.now().toString() };
        } else {
            return   rejectWithValue('no_role');
        }
    } else {
        return  rejectWithValue('no_active_user');
    }

},serviceOptions);

const authentifier = createAsyncThunk('authentication/authentifier', async (authParams: IUser, { dispatch,rejectWithValue },) => {
    const result = await axios.post<any>(API_URL + '/api/authenticate', authParams);
    const response = result as AxiosResponse;
    const bearerToken = response?.headers?.authorization;
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        dispatch(getAccount(jwt));
        
    } else {
        return  rejectWithValue('no_token');
    }
   
},serviceOptions);
 
const modifierMotDePasse = createAsyncThunk('authentication/modifierMotDePasse', async (changementMotDePasse: IChangementMotDePasse) => {
    await axios.post<any>(API_URL + '/api/account/change-password', {currentPassword:changementMotDePasse.ancienMotDePasse, newPassword:changementMotDePasse.nouveauMotDePasse});
}, serviceOptions);
 

export const logout = createAsyncThunk('auth/logout', async () => {
    return true;
});

const AuthService = {
    getAccount,
    authentifier,
    logout,
    modifierMotDePasse
};

export default AuthService;
