import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorMessage, ISuccessMessage } from './session.modele';

export type SessionState = Readonly<typeof initialState>;

const initialState = {
    actionErrorMessage: null as IErrorMessage,
    actionPending: false,
    actionSuccessMessage: null as ISuccessMessage
};

export const SessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {      
        setPendingAction: (state, action: PayloadAction<boolean>) => {
            state.actionPending = action.payload;
        },
        setActionSuccessMessage: (state, action: PayloadAction<ISuccessMessage>) => {
            state.actionSuccessMessage = action.payload;
        },
        setActionErrorMessage: (state, action: PayloadAction<any>) => {
            state.actionErrorMessage = action.payload;
        },

        initialiserActionErrorMessage: (state) => {
            state.actionErrorMessage = null;
        },
        initialiserActionSuccessMessage: (state) => {
            state.actionSuccessMessage = null;
        }
    }
});

export const SessionStore = SessionSlice.actions;

export default SessionSlice.reducer;
