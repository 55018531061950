import { SessionStore } from 'core/session/session.store';
import { AnyAction } from 'redux';

function isRejectedAction(action: AnyAction) {
    return action && action.type && action.type.endsWith('/rejected');
}

function isPendingAction(action: AnyAction) {
    return action && action.type && action.type.endsWith('/pending');
}

function isFulfilledAction(action: AnyAction) {
    return action && action.type && action.type.endsWith('/fulfilled');
}

export const serviceOptions = {
    serializeError: (value: any) => {
        if (typeof value === 'object' && value !== null && value.isAxiosError && value.response) {
            return { code: value.response.data.code, message: value.response.data.message, params: value.response.data.params, status: value.response.status };
        }
        return { message: String(value) };
    }
};

const notificationMiddelWare = (store) => (next) => (action) => {
    const { error, payload } = action;

    if (isFulfilledAction(action)) {
        store.dispatch(SessionStore.setPendingAction(false));
        const actionFrom = action.type.indexOf('/');
        const actionTo = action.type.indexOf('/fulfilled');
        const actionType = action.type.substr(actionFrom + 1, actionTo - actionFrom - 1);
        //const modelType = action.type.substr(0, actionFrom);
        //if (actionType === 'creer' || actionType === 'modifier') {
        //    store.dispatch(SessionStore.setActionSuccessMessage({ key: actionType, type: modelType, data: payload }));
        //}

        if (payload && payload._type) {
            const data = payload.data ? payload.data : payload;
            store.dispatch(SessionStore.setActionSuccessMessage({ key: actionType, type: payload._type, id: data?.id, libelle: data?.libelle  }));
        } else {
            store.dispatch(SessionStore.setActionSuccessMessage({ key: actionType }));
        }
    }

    if (isPendingAction(action)) {
        store.dispatch(SessionStore.setPendingAction(true));
        store.dispatch(SessionStore.initialiserActionSuccessMessage());
    }

    if (isRejectedAction(action) && error) {
        store.dispatch(SessionStore.setPendingAction(false));
        store.dispatch(SessionStore.initialiserActionSuccessMessage());
        if (error.status) {
            switch (error.status) {
            case 0:
                store.dispatch(SessionStore.setActionErrorMessage({ code: 'error.server.not.reachable' }));
                break;
            case 400:
                store.dispatch(SessionStore.setActionErrorMessage(error?.code ? error : { code: 'error.bad.request' }));
                break;
            case 401:
                store.dispatch(SessionStore.setActionErrorMessage(error?.code ? error : { code: 'error.unauthorized' }));
                break;
            case 404:
                store.dispatch(SessionStore.setActionErrorMessage({ code: 'error.url.not.found' }));
                break;
            case 500:
                store.dispatch(SessionStore.setActionErrorMessage({ code: 'error.server.error' }));
                break;
            }
        } else if(payload) {
            store.dispatch(SessionStore.setActionErrorMessage({ code: payload }));
        }
    }
    return next(action);
};

export default notificationMiddelWare;
