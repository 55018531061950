import { Layout, notification } from 'antd';
import LayoutHeader from './lheader';
import LayoutFooter from './lfooter';
import DialogueErreur from 'composants/dialogue/dialogue.erreur';
import { useEffect } from 'react';
import { useAppSelector } from 'config/store.config';
import Sablier from 'composants/conteneur/sablier';
import messages from 'util/messageMapping';
import { Outlet } from 'react-router';
import { Content } from 'antd/lib/layout/layout';
import { SLayoutSansMenu } from './styles';

const LayoutPriveSansMenu = () => {
    const actionPending = useAppSelector((state) => state.sessionState.actionPending);
    const actionSuccessMessage = useAppSelector((state) => state.sessionState.actionSuccessMessage);

    useEffect(
        () => {
            const message = messages.get(actionSuccessMessage);
            if (message) {
                notification['success']({
                    message: message
                });
            }
        },
        [ actionSuccessMessage ]
    );

    return (
        <Sablier si={actionPending}>
            <Layout>
                <LayoutHeader />
                <SLayoutSansMenu>
                    <Content>
                        <Outlet />
                    </Content>
                </SLayoutSansMenu>
                <LayoutFooter />
                <DialogueErreur />
            </Layout>
        </Sablier>
    );
};

export default LayoutPriveSansMenu;
