import labels from 'util/labelMapping';
import useHasRight from 'util/useHasRight';
import { SLinkSecondaire } from './styles';

const ActionLienSecondaire = ({ nom, action, libelle = null, icone = null }) => {
    const hasRight = useHasRight(nom);
    return (
        <span className="btn-wrapper">
            {hasRight && (
                <SLinkSecondaire type="link" onClick={action} icon={icone}  >
                    {libelle ? libelle : labels.action(nom)}
                </SLinkSecondaire>
            )}
        </span>
    );
};

export default ActionLienSecondaire;
