import { useAppDispatch, useAppSelector } from 'config/store.config';
import { useEffect } from 'react';
import DialogueConfirmation from 'composants/dialogue/dialogue.confirmation';
import { useForm } from 'antd/lib/form/Form';
import AuthService from './auth.service';
import { Form, Input } from 'antd';

const DialogueChangerMotDePasse = ({ visible, setVisible }) => {
    const dispatch = useAppDispatch();
    const actionReussie = useAppSelector((state) => state.authState.actionReussie);
    const [ form ] = useForm();

    useEffect(
        () => {
            if (actionReussie) {
                fermerDialog();
            }
        },
        [ actionReussie ]
    );

    const modifierMotDePasse = () => {
        form.validateFields().then(() => {
            const valeurs = form.getFieldsValue();
            dispatch(AuthService.modifierMotDePasse(valeurs));
        });
    };

    const fermerDialog = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <DialogueConfirmation titre={'Modifier mot de passe'} visible={visible} actionAnnuler={fermerDialog} actionConfirmer={modifierMotDePasse} nomActionConfirmer="Modifier">
            <Form name="basic" form={form} initialValues={{}} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={modifierMotDePasse}>
                <Form.Item label="Ancien mot de passe" name="ancienMotDePasse" rules={[ { required: true, message: 'Ancien mot de passe requis' } ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label="Nouveau mot de passe" name="nouveauMotDePasse" rules={[ { required: true, message: 'Nouveau mot de passe requis' } ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="nouveauMotDePasseConfirme"
                    label="Confirmer Nouveau mot de passe"
                    dependencies={[ 'nouveauMotDePasse' ]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Confirmation Nouveau mot de passe requise'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('nouveauMotDePasse') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Les deux mots de passe ne corespondent pas'));
                            }
                        })
                    ]}>
                    <Input.Password />
                </Form.Item>
            </Form>
        </DialogueConfirmation>
    );
};

export default DialogueChangerMotDePasse;
