import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { HomeOutlined, FolderOutlined, MinusOutlined } from '@ant-design/icons';
import labels from 'util/labelMapping';

const AdminMenu = ({ initialPath }) => {

    const items = [
        {  key:'home', icon:<HomeOutlined />, label:<Link to="/">Home</Link> },
        {  key:'alerteList', icon:<MinusOutlined />, label: <Link to='/alerte/list'>{labels.get('alerte.liste')}</Link> },
        {  key:'compagnieList', icon:<MinusOutlined />, label: <Link to='/compagnie/list'>{labels.get('compagnie.liste')}</Link> },
        {  key:'demandeList', icon:<MinusOutlined />, label: <Link to='/demande/list'>{labels.get('demande.liste')}</Link> },
        {  key:'demandeurList', icon:<MinusOutlined />, label: <Link to='/demandeur/list'>{labels.get('demandeur.liste')}</Link> },
        {  key:'relanceList', icon:<MinusOutlined />, label: <Link to='/relance/list'>{labels.get('relance.liste')}</Link> },
        {  key:'parametrage', icon:<FolderOutlined />, label: labels.get('parametrage'), 
            children: [   
                {  key:'actualiteList', icon:<MinusOutlined />, label: <Link to='/actualite/list'>{labels.get('actualite.liste')}</Link> },
                {  key:'brancheList', icon:<MinusOutlined />, label: <Link to='/branche/list'>{labels.get('branche.liste')}</Link> },
                {  key:'civiliteList', icon:<MinusOutlined />, label: <Link to='/civilite/list'>{labels.get('civilite.liste')}</Link> },
                {  key:'etatDemandeList', icon:<MinusOutlined />, label: <Link to='/etatDemande/list'>{labels.get('etatDemande.liste')}</Link> },
                {  key:'etatDemandeHistList', icon:<MinusOutlined />, label: <Link to='/etatDemandeHist/list'>{labels.get('etatDemandeHist.liste')}</Link> },
                {  key:'natureLitigeList', icon:<MinusOutlined />, label: <Link to='/natureLitige/list'>{labels.get('natureLitige.liste')}</Link> },
                {  key:'natureSinistreList', icon:<MinusOutlined />, label: <Link to='/natureSinistre/list'>{labels.get('natureSinistre.liste')}</Link> },
                {  key:'objetLitigeList', icon:<MinusOutlined />, label: <Link to='/objetLitige/list'>{labels.get('objetLitige.liste')}</Link> },
                {  key:'resultatDemandeList', icon:<MinusOutlined />, label: <Link to='/resultatDemande/list'>{labels.get('resultatDemande.liste')}</Link> },
                {  key:'typeDemandeurList', icon:<MinusOutlined />, label: <Link to='/typeDemandeur/list'>{labels.get('typeDemandeur.liste')}</Link> },
                {  key:'villeList', icon:<MinusOutlined />, label: <Link to='/ville/list'>{labels.get('ville.liste')}</Link> },
            ]},
    ];

    return (
        <Menu items={items} mode="inline" defaultSelectedKeys={[initialPath]} defaultOpenKeys={['setting']}/>
    );
  
};

export default AdminMenu;
