const map = {
    'no_token':'No Token',
    'no_active_user':'No Active User',
    'no_role':'No Role',
    'error.server.not.reachable':'Server Not Reachable',
    'error.url.not.found':'URl Not Found',
    'error.unicity':'Unicity Constraint',
    'error.validation':'Incomplete or invalid data',
    'NotNull.company.name':'Company Name is Required',
    'unicity.company.name':'A campany with the same name already exists, name must be unique',
    'error.bad.request':'Requete mal formatée',
    'error.unauthorized':'Login ou mot de passe invalide',
    'error.server.error':'Erreur Serveur',
};

const get = (key: string): string[] => {
    const value = map[key];
    if(!value){
        return ['['+key+']','ND'];
    }
    return value;
};

const errors = {
    get,
};

export default errors;
