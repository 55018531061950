import { useAppSelector } from 'config/store.config';

const rightMap = {
    ALL: [
        'accueil', //
        'logout',
        'filtrer',
        'actionEnEtude',
        'actionComplement',
        'actionAttenteCie',
        'actionTraitementCie',
        'actionCloturee',
        'actionRetourEtude',
        'actionRetourTraitement',
        'retourListe.demande',
        'completerInformation',
        'chercherDemande',
        'changerMotDePasse',
    ],
    ROLE_ADMIN: [
        'ajouter.user',
        'editer.user',
        'enregistrer.user',
        'supprimer.user',
        'retourListe.user',
        'ajouter.etatDemandeHist',
        'editer.etatDemandeHist',
        'enregistrer.etatDemandeHist',
        'supprimer.etatDemandeHist',
        'retourListe.etatDemandeHist',
        'ajouter.alerte',
        'editer.alerte',
        'enregistrer.alerte',
        'supprimer.alerte',
        'retourListe.alerte',
        'ajouter.media',
        'editer.media',
        'enregistrer.media',
        'supprimer.media',
        'retourListe.media',
        'ajouter.etatDemande',
        'editer.etatDemande',
        'enregistrer.etatDemande',
        'supprimer.etatDemande',
        'retourListe.etatDemande',
        'ajouter.objetLitige',
        'editer.objetLitige',
        'enregistrer.objetLitige',
        'supprimer.objetLitige',
        'retourListe.objetLitige',
        'ajouter.natureLitige',
        'editer.natureLitige',
        'enregistrer.natureLitige',
        'supprimer.natureLitige',
        'retourListe.natureLitige',
        'ajouter.compagnie',
        'editer.compagnie',
        'enregistrer.compagnie',
        'supprimer.compagnie',
        'retourListe.compagnie',
        'ajouter.branche',
        'editer.branche',
        'enregistrer.branche',
        'supprimer.branche',
        'retourListe.branche',
        'ajouter.relance',
        'editer.relance',
        'enregistrer.relance',
        'supprimer.relance',
        'retourListe.relance',
        'ajouter.demande',
        'editer.demande',
        'enregistrer.demande',
        'supprimer.demande',
        'retourListe.demande',
        'ajouter.civilite',
        'editer.civilite',
        'enregistrer.civilite',
        'supprimer.civilite',
        'retourListe.civilite',
        'ajouter.typeDemandeur',
        'editer.typeDemandeur',
        'enregistrer.typeDemandeur',
        'supprimer.typeDemandeur',
        'retourListe.typeDemandeur',
        'ajouter.resultatDemande',
        'editer.resultatDemande',
        'enregistrer.resultatDemande',
        'supprimer.resultatDemande',
        'retourListe.resultatDemande',
        'ajouter.natureSinistre',
        'editer.natureSinistre',
        'enregistrer.natureSinistre',
        'supprimer.natureSinistre',
        'retourListe.natureSinistre',
        'ajouter.demandeur',
        'editer.demandeur',
        'enregistrer.demandeur',
        'supprimer.demandeur',
        'retourListe.demandeur',
        'ajouter.actualite',
        'editer.actualite',
        'enregistrer.actualite',
        'supprimer.actualite',
        'retourListe.actualite',
        'ajouter.ville',
        'editer.ville',
        'enregistrer.ville',
        'supprimer.ville',
        'retourListe.ville',
    ],
    ROLE_MEDIATEUR: ['liste.demande'],
    ROLE_ASSURANCE: [],
};

export default function useHasRight(action): boolean {
    const user = useAppSelector((state) => state.authState.user);
    return action && (rightMap['ALL'].includes(action) || rightMap[user.role].includes(action));
}
