export const DOMAINE_ADMIN = 'admin'; 
export const DOMAINE_MEDIATEUR = 'mediateur'; 
export const DOMAINE_ASSURANCE = 'assurance';

const roleADomaineMap = {
    ROLE_ADMIN:DOMAINE_ADMIN,
    ROLE_MEDIATEUR:DOMAINE_MEDIATEUR,
    ROLE_ASSURANCE:DOMAINE_ASSURANCE,
};

export const DomaineConfig = {
    roleADomaine: (role) => roleADomaineMap[role],
};
