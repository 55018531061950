import labels from 'util/labelMapping';
import useHasRight from 'util/useHasRight';
import { SButtonSecondaire } from './styles';

const ActionSecondaire = ({ nom, action, type='tracee', libelle = null, icone = null }) => {
    const hasRight = useHasRight(nom);
    return (
        <span className="btn-wrapper">
            {hasRight && (
                <SButtonSecondaire onClick={action} icon={icone} className={type}>
                    {libelle ? libelle : labels.action(nom)}
                </SButtonSecondaire>
            )}
        </span>
    );
};

export default ActionSecondaire;
