import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IMedia, IRequeteMedia } from './media.modele';

const lister = createAsyncThunk('media/lister', async (requeteMedia: IRequeteMedia) => {
    let mediaListe: IMedia[] = [] as IMedia[];
    if (requeteMedia) {
        mediaListe = (await axios.get(`api/pbl/media/ownerType/${requeteMedia.ownerType}/ownerId/${requeteMedia.ownerId}`)).data;
    }
    return mediaListe;
});

const recuperer = createAsyncThunk('media/recuperer', async (id: string) => {
    return (await axios.get(`api/pbl/media/${id}`)).data as IMedia;
});

const MediaService = {
    lister,
    recuperer,
};

export default MediaService;
