import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { useAppSelector } from 'config/store.config';
import { DOMAINE_ASSURANCE, DOMAINE_ADMIN, DOMAINE_MEDIATEUR } from 'config/domaine.config';
import RootMenu from 'domaines/assurance/menu';
import AdminMenu from 'domaines/admin/menu';
import MediateurMenu from 'domaines/mediateur/menu';

const LayoutSidebar = () => {
     
    const location = useLocation();
    const {Sider} = Layout;
    const user = useAppSelector((state) => state.authState.user);

    const getMenu = useCallback(
        () => {
            switch (user.domaine) {
            case DOMAINE_ASSURANCE:
                return <RootMenu initialPath={location.pathname} />;
            case DOMAINE_ADMIN:
                return <AdminMenu initialPath={location.pathname} />;
            case DOMAINE_MEDIATEUR:
                return <MediateurMenu initialPath={location.pathname} />;
            default:
                break;
            }
        },
        [ user, location.pathname ]
    );

    return <Sider width={250}>{getMenu()}</Sider>;
};

export default LayoutSidebar;
