import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IRootState } from 'config/store.config';
import PaginationMapper from 'core/pagination/pagination.mapper';
import { IEtatDemandeHist } from 'modules/etatDemandeHist/etatDemandeHist.modele';
import { Page } from 'proxy/commun/representation/Page';
import { Pageable } from 'proxy/commun/representation/Pageable';
import { serviceOptions } from 'util/notificationMiddleWare';
import DemandeMapper from './demande.mapper';
import { IDemande, IListePagineeDemande, IRequeteDemande } from './demande.modele';

const recupererPourSuivi = createAsyncThunk('demande/recupererPourSuivi', async (requeteDemande: IRequeteDemande) => {
    const response = await axios.post('api/pbl/demande/suivi', { nom: requeteDemande.demandeur.libelle, prenom: requeteDemande.demandeur.prenom, libelle: requeteDemande.libelle });
    return response.data && response.data.length > 0 ? response.data[0] : null;
});

const recupererParDemande = createAsyncThunk('demande/recupererParDemande', async (demandeId: string) => {
    const response = await axios.get(`api/pbl/demande/${demandeId}`);
    return response.data;
});

const recupererDemandeur = createAsyncThunk('demande/recupererDemandeur', async (demandeurId: string) => {
    const response = await axios.get(`api/pbl/demandeur/${demandeurId}`);
    return response.data;
});

const recupererParNumero = createAsyncThunk('demande/recuperer', async (numeroDemande: string) => {
    const response = await axios.get(`api/pbl/demande/numero/${numeroDemande}`);
    return response.data;
});

const listeNombreParMoisAnneeEnCours = createAsyncThunk('demande/listeNombreParMoisAnneeEnCours', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParMoisAnneeEnCours');
    return response.data;
});

const listeNombreParAnnee = createAsyncThunk('demande/listeNombreParAnnee', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParAnnee');
    return response.data;
});

const listeNombreParCompagnie = createAsyncThunk('demande/listeNombreParCompagnie', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParCompagnie');
    return response.data;
});

const listeNombreParCompagnieParAnnee = createAsyncThunk('demande/listeNombreParCompagnieParAnnee', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParCompagnieParAnnee');
    return DemandeMapper.creerListeNombreDemandeParChampParAnnee(response.data);
});

const listeNombreParEtat = createAsyncThunk('demande/listeNombreParEtat', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParEtat');
    return response.data;
});

const listeNombreParEtatParAnnee = createAsyncThunk('demande/listeNombreParEtatParAnnee', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParEtatParAnnee');
    return DemandeMapper.creerListeNombreDemandeParChampParAnnee(response.data);
});

const listeNombreParEtatAnneeEnCours = createAsyncThunk('demande/listeNombreParEtatAnneeEnCours', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParEtatAnneeEnCours');
    return response.data;
});

const listeNombreParResultatCloture = createAsyncThunk('demande/listeNombreParResultatCloture', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParResultatCloture');
    return response.data;
});

const listeNombreParResultatClotureParAnnee = createAsyncThunk('demande/listeNombreParResultatClotureParAnnee', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParResultatClotureParAnnee');
    return DemandeMapper.creerListeNombreDemandeParChampParAnnee(response.data);
});

const listeNombreParResultatClotureAnneeEnCours = createAsyncThunk('demande/listeNombreParResultatClotureAnneeEnCours', async (_) => {
    const response = await axios.get('api/pbl/demande/listeNombreParResultatClotureAnneeEnCours');
    return response.data;
});

const lister = createAsyncThunk('demande/lister', async (requeteDemande: IRequeteDemande, thunkAPI) => {
    let listePagineeDemande: IListePagineeDemande = {} as IListePagineeDemande;
    if (requeteDemande) {
        const state = thunkAPI.getState() as IRootState;
        const compagnie = state.authState.user.compagnie;
        const pageable: Pageable = PaginationMapper.creerPageable(requeteDemande);
        const pageDemande: Page<IDemande> = (await axios.post(`api/pbl/demande/filter?page=${pageable.page}&size=${pageable.size}`, { ...requeteDemande, compagnie })).data;
        listePagineeDemande = DemandeMapper.creerListePagineeDemande(pageDemande);
        listePagineeDemande.requete = requeteDemande;
    }
    return listePagineeDemande;
});

const creer = createAsyncThunk(
    'demande/creer',
    async (demande: IDemande) => {
        const resultat = await axios.post('api/pbl/demande', demande);
        return resultat.data;
    },
    serviceOptions
);

const modifier = createAsyncThunk(
    'demande/modifier',
    async (demande: IDemande) => {
        const resultat = await axios.put('api/prv/demande', demande);
        return resultat.data;
    },
    serviceOptions
);

const modifierEtat = createAsyncThunk(
    'demande/modifierEtat',
    async (etatDemandeHist: IEtatDemandeHist) => {
        const resultat = await axios.put(`api/prv/demande/${etatDemandeHist.demande.id}/etat/${etatDemandeHist.etatDemande.id}`);
        return resultat.data;
    },
    serviceOptions
);

const recupererLastEtatDemandeHist = createAsyncThunk('demande/recupererLastDemandeHist', async (demandeId: number) => {
    const response = await axios.get(`api/pbl/etatDemandeHist/last/${demandeId}`);
    return response.data;
});

const DemandeService = {
    recupererPourSuivi,
    recupererParDemande,
    recupererDemandeur,
    recupererParNumero,
    listeNombreParMoisAnneeEnCours,
    listeNombreParAnnee,
    listeNombreParCompagnie,
    listeNombreParCompagnieParAnnee,
    listeNombreParEtat,
    listeNombreParEtatParAnnee,
    listeNombreParEtatAnneeEnCours,
    listeNombreParResultatCloture,
    listeNombreParResultatClotureParAnnee,
    listeNombreParResultatClotureAnneeEnCours,
    lister,
    creer,
    modifier,
    modifierEtat,
    recupererLastEtatDemandeHist,
};

export default DemandeService;
