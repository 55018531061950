import React, { Suspense } from 'react';
import { Route } from 'react-router';

const ListDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.assurance.liste'));
const ViewDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.assurance.view'));
 
export const ListDemandePage = (props) => (
    <Suspense fallback="">
        <ListDemandePageLazy {...props} />
    </Suspense>
);  
export const ViewDemandePage = (props) => (
    <Suspense fallback="">
        <ViewDemandePageLazy {...props} />
    </Suspense>
); 
 

const RouteRoutes = () => {
    const map=[];
    map.push(<Route key="300"  path="/" element={<ListDemandePage />} />);
    map.push(<Route key="300"  path="/demande/list" element={<ListDemandePage />} />);
    map.push(<Route key="303" path="/demande/view/:demandeId" element={<ViewDemandePage />} />);
    return map;
};
export default RouteRoutes;
