import { createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit';
import { IListePagineeDemande, IDemande, IRequeteDemande } from './demande.modele';
import DemandeService from './demande.service';
import { IDemandeur } from 'modules/demandeur/demandeur.modele';

export type DemandeState = Readonly<typeof initialState>;

const initialState = {
    demande: {} as IDemande,
    demandeur: {} as IDemandeur,
    requeteDemande: {} as IRequeteDemande,
    listeDemande: {} as IListePagineeDemande,
    demandeCree:  {} as IDemande,
    nombreParMoisAnneeEnCours : [],
    nombreParAnnee: [],
    nombreParCompagnie : [],
    nombreParCompagnieParAnnee : [],
    nombreParEtat : [],
    nombreParEtatParAnnee: [],
    nombreParEtatAnneeEnCours: [],
    nombreParResultatCloture : [],
    nombreParResultatClotureParAnnee: [],
    nombreParResultatClotureAnneeEnCours : []
};

export const DemandeSlice = createSlice({
    name: 'demande',
    initialState,
    reducers: {
        initialiser: () => {
            return initialState;
        },
        setPageCourante: (state, action: PayloadAction<number>) => {
            state.requeteDemande.pageCourante = action.payload;
        },
        setRequeteDemande: (state, action: PayloadAction<IRequeteDemande>) => {
            state.requeteDemande = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            
            .addCase(DemandeService.lister.fulfilled, (state, action) => {
                state.listeDemande = action.payload;
                state.requeteDemande = action.payload.requete;
            })
            .addCase(DemandeService.modifierEtat.fulfilled, (state, action) => {
                state.demande = action.payload;
            })
            .addCase(DemandeService.creer.fulfilled, (state, action) => {
                state.demandeCree = action.payload;
            })
            .addCase(DemandeService.modifier.fulfilled, (state, action) => {
                state.demande = action.payload;
            })
            .addCase(DemandeService.recupererDemandeur.fulfilled, (state, action) => {
                state.demandeur = action.payload;
            })
            .addCase(DemandeService.listeNombreParMoisAnneeEnCours.fulfilled, (state, action) => {
                state.nombreParMoisAnneeEnCours = action.payload;
            })
            .addCase(DemandeService.listeNombreParAnnee.fulfilled, (state, action) => {
                state.nombreParAnnee = action.payload;
            })
            .addCase(DemandeService.listeNombreParCompagnie.fulfilled, (state, action) => {
                state.nombreParCompagnie = action.payload;
            })
            .addCase(DemandeService.listeNombreParCompagnieParAnnee.fulfilled, (state, action) => {
                state.nombreParCompagnieParAnnee = action.payload;
            })
            .addCase(DemandeService.listeNombreParEtat.fulfilled, (state, action) => {
                state.nombreParEtat = action.payload;
            })
            .addCase(DemandeService.listeNombreParEtatParAnnee.fulfilled, (state, action) => {
                state.nombreParEtatParAnnee = action.payload;
            })
            .addCase(DemandeService.listeNombreParEtatAnneeEnCours.fulfilled, (state, action) => {
                state.nombreParEtatAnneeEnCours = action.payload;
            })
            .addCase(DemandeService.listeNombreParResultatCloture.fulfilled, (state, action) => {
                state.nombreParResultatCloture = action.payload;
            })
            .addCase(DemandeService.listeNombreParResultatClotureParAnnee.fulfilled, (state, action) => {
                state.nombreParResultatClotureParAnnee = action.payload;
            })
            .addCase(DemandeService.listeNombreParResultatClotureAnneeEnCours.fulfilled, (state, action) => {
                state.nombreParResultatClotureAnneeEnCours = action.payload;
            })
            .addMatcher(isFulfilled(DemandeService.recupererPourSuivi, DemandeService.recupererParNumero, DemandeService.recupererParDemande), (state, action) => {
                state.demande = action.payload;
                state.demandeCree ={} as IDemande;
            });
    },
});

export const DemandeStore = DemandeSlice.actions;

export default DemandeSlice.reducer;
