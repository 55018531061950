import { Layout } from 'antd';
import { Footer, Header } from 'antd/lib/layout/layout';
import { couleurs } from 'assets/styles/theme';
import styled from 'styled-components';

export const SLayoutSansMenu = styled(Layout)`
    margin-top: 40px;
    min-height: calc(100vh - 100px);
    .ant-layout-content {
        margin: auto;
        width: 1340px;
    }
`;

export const SFooterSansMenu = styled(Footer)`
    text-align: center;
    color: #999;
    font-weight: 700;
    font-family: 'ROBOTO';
    padding: 0;
    margin: 0;
    background-color: transparent;
`;

export const SHeaderSansMenu = styled(Header)`
    background: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0 5px 2px #eee;
    z-index: 999;
    padding: 0;
    .header {
        max-width: 1340px;
        margin: auto;
        .logo {
            cursor: pointer;
            img {
                width: 160px;
            }
        }
        .alerte {
            .ant-popover-inner-content {
                text-align: center;
            }
            .ant-avatar {
                padding: 0;
                margin-left: 10px;
                cursor: pointer;

                &.alerte {
                    background-color: #fcfcfc;
                    color: ${couleurs.principale};
                    border: 1px solid ${couleurs.principale};
                    &:hover {
                        background-color: ${couleurs.principale};
                        color: #fefefe;
                    }
                }
                &.user {
                    background-color: #fcfcfc;
                    color: ${couleurs.secondaire};
                    border: 1px solid ${couleurs.secondaire};

                    &:hover {
                        background-color: ${couleurs.secondaire};
                        color: #fefefe;
                    }
                }
            }
        }
    }
`;

export const SUserPopup = styled.div`
    text-align: center;

    .nomPrenom {
        font-weight: bold;
    }
    .domain {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
    }
`;
