import styled from 'styled-components';
import { Button, Col } from 'antd';
import { couleurs } from 'assets/styles/theme';

export const SButtonPrincipal = styled(Button)`
  font-weight: 500;
  border-color: ${couleurs.principale};
  &.tracee{
    color: ${couleurs.principale};
    background-color: white;
    &:hover {
      color: white;
      background-color: ${couleurs.principale};
    }
  }
  &.remplie{
    color: white;
    background-color: ${couleurs.principale};
    &:hover {
      color: ${couleurs.principale};
      background-color: white;
    }
}
`;

export const SButtonSecondaire = styled(Button)`
  font-weight: 500;
  border-color: ${couleurs.secondaire};
  &:focus{
    border-color: ${couleurs.secondaire};
  }
  &.tracee{
    color: ${couleurs.secondaire};
    background-color: white;
    &:hover {
      color: white;
      background-color: ${couleurs.secondaire};
      border-color: ${couleurs.secondaire};
    }
  }
  &.remplie{
    color: white;
    background-color: ${couleurs.secondaire};
    &:hover {
      color: ${couleurs.secondaire};
      border-color: ${couleurs.secondaire};
      background-color: white;
    }
}
`;

export const SLinkPrincipal = styled(Button)`
  font-weight: 500;
    color: #777;
    cursor: pointer ;
    &:hover {
    color: ${couleurs.principale};
    span{
      text-decoration: underline;
    }
`;

export const SLinkSecondaire = styled(Button)`
    font-weight: 500;
    color:#777;
    cursor: pointer ;
    &:hover {
      color: ${couleurs.secondaire};
      span{
        text-decoration: underline;
      }
`;
 

export const SActionBlocDroit = styled(Col)`
  text-align:  right;
  line-height: 36px;
  margin-top:10px;
  .btn-wrapper {
    margin: 0 5px;
  }
  .btn-wrapper:first-child {
    margin: 0 5px;
  }
  .btn-wrapper:last-child {
    margin: 0 0 0 5px;
  }
`;


export const SActionBlocGauche = styled(Col)`
  text-align:  left;
  line-height: 36px;
  margin-top:10px;
  .btn-wrapper {
    margin: 0 5px;
  }
  .btn-wrapper:first-child {
    margin: 0 5px 0 0 ;
  }
  .btn-wrapper:last-child {
    margin: 0 5px ;
  }
`;

export const SActionMini = styled.span`
    padding: 5px;
    cursor: pointer;
    color: ${couleurs.principale};
    border-radius: 2px;
    .libelle {
        color: transparent;
        margin-right: 5px;
    }
    .icon {
    }
    &:hover {
        color: white;
        background-color: ${couleurs.principale};
        .libelle {
            color: white;
            margin-right: 5px;
        }
        .icon {
        }
    }
`;
export const SButtonIcon = styled(Button)`
  background-color: ${couleurs.principale};
  color:white; 
`;

export const SActionInnerBloc = styled(Col)`
 .btn-wrapper{
  margin: 10px;
 }
 .btn-wrapper:first-child {
  margin: 10px 5px 10px 0;
 }
 .btn-wrapper:last-child {
  margin: 10px 10px 10px 5px ;
 }
`;
