import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMedia, IRequeteMedia } from './media.modele';
import MediaService from './media.service';

export type MediaState = Readonly<typeof initialState>;

const initialState = {
    requeteMedia: {} as IRequeteMedia,
    listeMedia: [] as IMedia[],
    media: {} as IMedia,
};

export const MediaSlice = createSlice({
    name: 'demande',
    initialState,
    reducers: {
        initialiser: () => {
            return initialState;
        },
        setRequeteMedia: (state, action: PayloadAction<IRequeteMedia>) => {
            state.requeteMedia = action.payload;
        },
    },
    extraReducers(builder) {
        builder

            .addCase(MediaService.lister.fulfilled, (state, action) => {
                state.listeMedia = action.payload;
            })
            .addCase(MediaService.recuperer.fulfilled, (state, action) => {
                state.media = action.payload;
            });
    },
});

export const MediaStore = MediaSlice.actions;

export default MediaSlice.reducer;
