import styled from 'styled-components';
import { Layout } from 'antd';
import { Footer, Header } from 'antd/lib/layout/layout';
import { couleurs } from 'assets/styles/theme';

export const SLayoutAvecMenu = styled(Layout)`
    margin:auto;
    width: 1340px;
    margin-top: 40px;
    min-height: calc(100vh - 100px);
    .ant-layout-sider{
        margin: 75px 0;
        padding:0;
        min-height: calc(100vh - 80px);
        .ant-menu-light .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active {
            background-color: rgba(236, 237, 241, 0.7);
        }
        .ant-menu-light .ant-menu-item:hover,
        .ant-menu-light .ant-menu-item-active {
            background-color: rgba(236, 237, 241, 0.7);
        }
        .ant-menu-light .ant-menu-item.ant-menu-item-selected:hover,
        .ant-menu-light .ant-menu-item-active.ant-menu-item-selected {
            background-color: rgba(1, 1, 161, 0.05);
        }
    }

    .ant-layout-content{
        width:1000px;
    }
`;

export const SFooterAvecMenu = styled(Footer)`
    text-align: center;
    color: #999;
    font-weight: 700;
    font-family: 'ROBOTO';
    padding:0;
    margin:0;
    background-color: transparent;
`;

export const SHeaderAvecMenu = styled(Header)`
    background: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0 5px 2px #eee;
    z-index: 999;
    padding:0;
    .header {
        max-width: 1340px;
        margin: auto;
        .logo {
            cursor: pointer;
            img {
                width: 160px;
            }
        }
        .alerte {
            .ant-popover-inner-content{
                text-align: center;
            }
            .ant-avatar {
                padding: 0;
                margin-left: 10px;
                cursor: pointer;

                &.alerte {
                    background-color: #fcfcfc;
                    color: ${couleurs.principale};
                    border: 1px solid ${couleurs.principale};
                    &:hover {
                        background-color: ${couleurs.principale};
                        color: #fefefe;
                    }
                }
                &.user {
                    background-color: #fcfcfc;
                    color: ${couleurs.secondaire};
                    border: 1px solid ${couleurs.secondaire};

                    &:hover {
                        background-color: ${couleurs.secondaire};
                        color: #fefefe;
                    }
                }
            }
        }
    }
`;

export const SUserPopup = styled.div`
  text-align: center;

  .nomPrenom{
      font-weight: bold;
  }
  .domain{
      padding-bottom:10px;
      margin-bottom:10px;
      border-bottom:1px solid #EEE;
  }

`;