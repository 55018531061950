import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceOptions } from 'util/notificationMiddleWare';
import { IEtatDemandeHist } from './etatDemandeHist.modele';
 

const recupererDernierParDemande = createAsyncThunk('etatDemandeHist/recupererDernierParDemande', async (demandeId:number) => {
    const response = await axios.get(`api/pbl/etatDemandeHist/last/${demandeId}`);
    return response.data;
},serviceOptions);


const creer = createAsyncThunk('etatDemandeHist/creer', async (entity: IEtatDemandeHist) => { 
    const entityCreated:IEtatDemandeHist= ( await axios.post<IEtatDemandeHist>('api/pbl/etatDemandeHist', entity)).data;
    return entityCreated;
},serviceOptions);



const EtatDemandeHistService = {   
    recupererDernierParDemande,
    creer
};

export default EtatDemandeHistService;
