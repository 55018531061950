import styled from 'styled-components';
import { couleurs } from 'assets/styles/theme';
import { Col, Row, Tabs, Spin } from 'antd';

export const SDemiPage = styled.div`
    //background-color: #fff;
   // box-shadow: 0 0 2px 1px #E9E9E9;
`;

export const SDemiPageEntete = styled(Row)`
  border-bottom: 1px solid  rgba(0, 147, 175,0.6) ;
  padding: 12px 0 0 0;
  color: ${couleurs.principale};
  font-size: 30px;
  font-weight: 300;
  text-transform: capitalize;
  font-family: "ROBOTO";
`;

export const SCrudInline = styled.div`
 
`;
export const SCrudInlineEntete = styled(Row)`

 background-color: #f6f7fb;
 border:1px solid #f0f0f0;
`;
export const SCrudInlineCorps = styled(Row)`
 padding-top:10px;
`;

export const SCrudInlineRightBloc = styled(Col)`
padding:10px 10px 10px 0px;
  border-right:1px solid #DDD;
`;
export const SCrudInlineLefttBloc = styled(Col)`
padding:10px 0 10px 10px;
`;

export const SBloc = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
`;

export const SEncadre = styled.div`
    border: 1px solid #f0f0f0;
`;

export const SEncadreEntete = styled.div`
      background-color: ${couleurs.principale};
      color:white;
    padding: 5px 16px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 90%;
`;

export const SEncadreCorps = styled.div`
 padding:10px;
`;

export const SMiniPanneau = styled.div`
    background-color: #fff;
    margin: 0 10px;
`;

export const SMiniPanneauEntete = styled(Row)`
  border-bottom: 1px solid ${couleurs.principale};
  background-color: #fff;
  padding: 0 5px 5px 5px;
  margin-bottom: 5px;
  color: ${couleurs.principale};
  font-weight: 700;
  font-size: 14px;
  .anticon-edit {
    padding: 3px;
    border-radius: 3px;
  }
  &:hover {
    .anticon-edit {
      color: white;
      background-color: ${couleurs.principale};
    }
  }
`;

export const SMiniPanneauCorps = styled(Row)`
  padding: 5px 0;
`;
 

export const SPanneau = styled.div`
    background-color: #fff;
    box-shadow: 0 0 1px 1px #E9E9E9;
`;

export const SPanneauEntete = styled(Row)`
  padding: 5px 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
  &.light{
    color: ${couleurs.principale};
    background-color:#FAFAFA ;
    border-bottom: 1px solid  rgba(0, 147, 175,0.6) ;
  }
  &.principale{
    color: #FFF;
    background-color:${couleurs.principale};
    border-bottom: ${couleurs.principale};
  }
  &.secondaire{
    color: #FFF;
    background-color:${couleurs.secondaire};
    border-bottom: ${couleurs.secondaire};
  }
`;

export const SPanneauCorps = styled(Row)`
  padding: 20px  ;
`;


export const SPage = styled.div``;

export const SPageEntete = styled(Row)`
  font-size: 30px;
  font-weight: 300;
  color: #999;
  text-transform: capitalize;
  font-family: "ROBOTO";
  .sub {
    font-weight: 500;
    color: #888;
  }
`;

export const SPageCorps = styled.div`
    margin-top:8px;
`;

export const STabs = styled(Tabs)`
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding:5px 10px;
      font-size: 16px;
      &:hover {
        background-color: rgba(236, 237, 241, 0.7);
      }
    }
  }
  .ant-tabs-content-holder {
  }
`;

export const SSablier = styled(Spin)`
`;