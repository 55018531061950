import { WarningOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import { SessionStore } from 'core/session/session.store';
import { useEffect, useState } from 'react';
import errors from 'util/errorMapping';
import { SDialogErreur, SDialogErreurEntete, SDialogErreurCorps, SDialogueErreurAction } from './styles';

const DialogueErreur = () => {
    const dispatch = useAppDispatch();
    const actionErrorMessage = useAppSelector((state) => state.sessionState.actionErrorMessage);
    const [ messageErreur, setMessageErreur ] = useState([]);

    const fermer = () => {
        dispatch(SessionStore.initialiserActionErrorMessage());
    };

    useEffect(
        () => {
            if (actionErrorMessage) {
                setMessageErreur(errors.get(actionErrorMessage.code));
            }
        },
        [ actionErrorMessage ]
    );

    return (
        <SDialogErreur visible={actionErrorMessage} closable={false}>
            <Row>
                <SDialogErreurEntete span="8">
                    <div className="icone">
                        <WarningOutlined />
                    </div>
                    <div className="titre">Erreur</div>
                </SDialogErreurEntete>

                <SDialogErreurCorps span="16">
                    <div className="titre">{messageErreur[0]}</div>
                    <div className="message">{messageErreur[1]}</div>
                </SDialogErreurCorps>
            </Row>
            <SDialogueErreurAction onClick={fermer}>Fermer</SDialogueErreurAction>
        </SDialogErreur>
    );
};
export default DialogueErreur;
