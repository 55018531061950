import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import brand from 'assets/images/brand.svg';
import Sablier from 'composants/conteneur/sablier';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import { SessionStore } from 'core/session/session.store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import errors from 'util/errorMapping';
import messages from 'util/messageMapping';
import AuthService from './auth.service';
import { FrameContent, LoginFrame, LogoHolder } from './style';

export const LoginPage = () => {
    const dispatch = useAppDispatch();
    const actionPending = useAppSelector((state) => state.sessionState.actionPending);
    const actionSuccessMessage = useAppSelector((state) => state.sessionState.actionSuccessMessage);
    const actionErrorMessage = useAppSelector((state) => state.sessionState.actionErrorMessage);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(AuthService.logout());
    }, [dispatch]);

    const signin = (values) => {
        dispatch(SessionStore.initialiserActionErrorMessage());
        dispatch(SessionStore.initialiserActionSuccessMessage());
        dispatch(AuthService.authentifier({ username: values.username, password: values.password }));
    };

    useEffect(() => {
        if (actionErrorMessage) {
            const message = errors.get(actionErrorMessage.code);
            if (message) {
                notification['error']({ message });
            }
        }
    }, [actionErrorMessage]);

    useEffect(() => {
        const message = messages.get(actionSuccessMessage);
        if (message) {
            const from = '/'; // state?.from?.pathname || '/';
            navigate(from, { replace: true });
        }
    }, [actionSuccessMessage]);

    const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
    const tailLayout = { wrapperCol: { span: 8, offset: 16 } };

    return (
        <LoginFrame>
            <Sablier si={actionPending}>
                <FrameContent>
                    <Form {...layout} name="basic" onFinish={signin}>
                        <LogoHolder>
                            <img src={brand} alt="logo" />
                        </LogoHolder>
                        <Form.Item label="Login" name="username" rules={[{ required: true, message: 'User Name required' }]}>
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User Name" />
                        </Form.Item>
                        <Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: 'Password required!' }]}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" block htmlType="submit">
                                Connexion
                            </Button>
                        </Form.Item>
                    </Form>
                </FrameContent>
            </Sablier>
        </LoginFrame>
    );
};

export default LoginPage;
