import { createSlice } from '@reduxjs/toolkit';
import { ICompagnie } from 'modules/compagnie/compagnie.modele';
import SessionStorage from 'util/session.storage';
import { IUser } from './auth.modele';
import AuthService from './auth.service';

export type AuthState = Readonly<typeof initialState>;

const initialState = {
    user: {} as IUser,
    compagnie: {} as ICompagnie,
    initialPage: null,
    actionReussie:false,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reload: (state) => {
            state.user = SessionStorage.getUser();
        },
        reset() {
            return { ...initialState };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(AuthService.authentifier.pending, () => {
                SessionStorage.clear();
            })
            .addCase(AuthService.authentifier.rejected, () => {
                return { ...initialState };
            })
            .addCase(AuthService.getAccount.fulfilled, (state, action) => {
                const u ={...state.user, ...action.payload};
                SessionStorage.setUser(u);
                state.user=u;
            })
            .addCase(AuthService.getAccount.rejected, () => {
                return { ...initialState };
            })
            .addCase(AuthService.logout.fulfilled, () => {
                SessionStorage.clear();
                return { ...initialState };
            })
            .addCase(AuthService.modifierMotDePasse.fulfilled, (state) => {
                state.actionReussie = true;
            });
    }
});

export const AuthStore = AuthSlice.actions;

export default AuthSlice.reducer;
