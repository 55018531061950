import React, { Suspense } from 'react';
import { Route } from 'react-router';

const AdminHomePageLazy = React.lazy(() => import('domaines/admin/home'));
const ListUserPageLazy = React.lazy(() => import('modules/user/user.ui.list'));
const ViewUserPageLazy = React.lazy(() => import('modules/user/user.ui.view'));
const EditUserPageLazy = React.lazy(() => import('modules/user/user.ui.edit'));
const ListAlertePageLazy = React.lazy(() => import('modules/alerte/alerte.ui.list'));
const ViewAlertePageLazy = React.lazy(() => import('modules/alerte/alerte.ui.view'));
const EditAlertePageLazy = React.lazy(() => import('modules/alerte/alerte.ui.edit'));
const ListMediaPageLazy = React.lazy(() => import('modules/media/media.ui.list'));
const ViewMediaPageLazy = React.lazy(() => import('modules/media/media.ui.view'));
const EditMediaPageLazy = React.lazy(() => import('modules/media/media.ui.edit'));
const ListEtatDemandePageLazy = React.lazy(() => import('modules/etatDemande/etatDemande.ui.list'));
const ViewEtatDemandePageLazy = React.lazy(() => import('modules/etatDemande/etatDemande.ui.view'));
const EditEtatDemandePageLazy = React.lazy(() => import('modules/etatDemande/etatDemande.ui.edit'));
const ListObjetLitigePageLazy = React.lazy(() => import('modules/objetLitige/objetLitige.ui.list'));
const ViewObjetLitigePageLazy = React.lazy(() => import('modules/objetLitige/objetLitige.ui.view'));
const EditObjetLitigePageLazy = React.lazy(() => import('modules/objetLitige/objetLitige.ui.edit'));
const ListNatureLitigePageLazy = React.lazy(() => import('modules/natureLitige/natureLitige.ui.list'));
const ViewNatureLitigePageLazy = React.lazy(() => import('modules/natureLitige/natureLitige.ui.view'));
const EditNatureLitigePageLazy = React.lazy(() => import('modules/natureLitige/natureLitige.ui.edit'));
const ListCompagniePageLazy = React.lazy(() => import('modules/compagnie/compagnie.ui.list'));
const ViewCompagniePageLazy = React.lazy(() => import('modules/compagnie/compagnie.ui.view'));
const EditCompagniePageLazy = React.lazy(() => import('modules/compagnie/compagnie.ui.edit'));
const ListBranchePageLazy = React.lazy(() => import('modules/branche/branche.ui.list'));
const ViewBranchePageLazy = React.lazy(() => import('modules/branche/branche.ui.view'));
const EditBranchePageLazy = React.lazy(() => import('modules/branche/branche.ui.edit'));
const ListRelancePageLazy = React.lazy(() => import('modules/relance/relance.ui.list'));
const ViewRelancePageLazy = React.lazy(() => import('modules/relance/relance.ui.view'));
const EditRelancePageLazy = React.lazy(() => import('modules/relance/relance.ui.edit'));
const ListDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.list'));
const ViewDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.view'));
const EditDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.edit'));
const ListCivilitePageLazy = React.lazy(() => import('modules/civilite/civilite.ui.list'));
const ViewCivilitePageLazy = React.lazy(() => import('modules/civilite/civilite.ui.view'));
const EditCivilitePageLazy = React.lazy(() => import('modules/civilite/civilite.ui.edit'));
const ListTypeDemandeurPageLazy = React.lazy(() => import('modules/typeDemandeur/typeDemandeur.ui.list'));
const ViewTypeDemandeurPageLazy = React.lazy(() => import('modules/typeDemandeur/typeDemandeur.ui.view'));
const EditTypeDemandeurPageLazy = React.lazy(() => import('modules/typeDemandeur/typeDemandeur.ui.edit'));
const ListResultatDemandePageLazy = React.lazy(() => import('modules/resultatDemande/resultatDemande.ui.list'));
const ViewResultatDemandePageLazy = React.lazy(() => import('modules/resultatDemande/resultatDemande.ui.view'));
const EditResultatDemandePageLazy = React.lazy(() => import('modules/resultatDemande/resultatDemande.ui.edit'));
const ListNatureSinistrePageLazy = React.lazy(() => import('modules/natureSinistre/natureSinistre.ui.list'));
const ViewNatureSinistrePageLazy = React.lazy(() => import('modules/natureSinistre/natureSinistre.ui.view'));
const EditNatureSinistrePageLazy = React.lazy(() => import('modules/natureSinistre/natureSinistre.ui.edit'));
const ListDemandeurPageLazy = React.lazy(() => import('modules/demandeur/demandeur.ui.list'));
const ViewDemandeurPageLazy = React.lazy(() => import('modules/demandeur/demandeur.ui.view'));
const EditDemandeurPageLazy = React.lazy(() => import('modules/demandeur/demandeur.ui.edit'));
const ListActualitePageLazy = React.lazy(() => import('modules/actualite/actualite.ui.list'));
const ViewActualitePageLazy = React.lazy(() => import('modules/actualite/actualite.ui.view'));
const EditActualitePageLazy = React.lazy(() => import('modules/actualite/actualite.ui.edit'));
const ListVillePageLazy = React.lazy(() => import('modules/ville/ville.ui.list'));
const ViewVillePageLazy = React.lazy(() => import('modules/ville/ville.ui.view'));
const EditVillePageLazy = React.lazy(() => import('modules/ville/ville.ui.edit'));

const AdminHomePage = (props) => (
    <Suspense fallback="">
        <AdminHomePageLazy {...props} />
    </Suspense>
);   
export const ListUserPage = (props) => (
    <Suspense fallback="">
        <ListUserPageLazy {...props} />
    </Suspense>
);   
export const ViewUserPage = (props) => (
    <Suspense fallback="">
        <ViewUserPageLazy {...props} />
    </Suspense>
); 
export const EditUserPage = (props) => (
    <Suspense fallback="">
        <EditUserPageLazy {...props} />
    </Suspense>
); 
export const ListAlertePage = (props) => (
    <Suspense fallback="">
        <ListAlertePageLazy {...props} />
    </Suspense>
);   
export const ViewAlertePage = (props) => (
    <Suspense fallback="">
        <ViewAlertePageLazy {...props} />
    </Suspense>
); 
export const EditAlertePage = (props) => (
    <Suspense fallback="">
        <EditAlertePageLazy {...props} />
    </Suspense>
); 
export const ListMediaPage = (props) => (
    <Suspense fallback="">
        <ListMediaPageLazy {...props} />
    </Suspense>
);   
export const ViewMediaPage = (props) => (
    <Suspense fallback="">
        <ViewMediaPageLazy {...props} />
    </Suspense>
); 
export const EditMediaPage = (props) => (
    <Suspense fallback="">
        <EditMediaPageLazy {...props} />
    </Suspense>
); 
export const ListEtatDemandePage = (props) => (
    <Suspense fallback="">
        <ListEtatDemandePageLazy {...props} />
    </Suspense>
);   
export const ViewEtatDemandePage = (props) => (
    <Suspense fallback="">
        <ViewEtatDemandePageLazy {...props} />
    </Suspense>
); 
export const EditEtatDemandePage = (props) => (
    <Suspense fallback="">
        <EditEtatDemandePageLazy {...props} />
    </Suspense>
); 
export const ListObjetLitigePage = (props) => (
    <Suspense fallback="">
        <ListObjetLitigePageLazy {...props} />
    </Suspense>
);   
export const ViewObjetLitigePage = (props) => (
    <Suspense fallback="">
        <ViewObjetLitigePageLazy {...props} />
    </Suspense>
); 
export const EditObjetLitigePage = (props) => (
    <Suspense fallback="">
        <EditObjetLitigePageLazy {...props} />
    </Suspense>
); 
export const ListNatureLitigePage = (props) => (
    <Suspense fallback="">
        <ListNatureLitigePageLazy {...props} />
    </Suspense>
);   
export const ViewNatureLitigePage = (props) => (
    <Suspense fallback="">
        <ViewNatureLitigePageLazy {...props} />
    </Suspense>
); 
export const EditNatureLitigePage = (props) => (
    <Suspense fallback="">
        <EditNatureLitigePageLazy {...props} />
    </Suspense>
); 
export const ListCompagniePage = (props) => (
    <Suspense fallback="">
        <ListCompagniePageLazy {...props} />
    </Suspense>
);   
export const ViewCompagniePage = (props) => (
    <Suspense fallback="">
        <ViewCompagniePageLazy {...props} />
    </Suspense>
); 
export const EditCompagniePage = (props) => (
    <Suspense fallback="">
        <EditCompagniePageLazy {...props} />
    </Suspense>
); 
export const ListBranchePage = (props) => (
    <Suspense fallback="">
        <ListBranchePageLazy {...props} />
    </Suspense>
);   
export const ViewBranchePage = (props) => (
    <Suspense fallback="">
        <ViewBranchePageLazy {...props} />
    </Suspense>
); 
export const EditBranchePage = (props) => (
    <Suspense fallback="">
        <EditBranchePageLazy {...props} />
    </Suspense>
); 
export const ListRelancePage = (props) => (
    <Suspense fallback="">
        <ListRelancePageLazy {...props} />
    </Suspense>
);   
export const ViewRelancePage = (props) => (
    <Suspense fallback="">
        <ViewRelancePageLazy {...props} />
    </Suspense>
); 
export const EditRelancePage = (props) => (
    <Suspense fallback="">
        <EditRelancePageLazy {...props} />
    </Suspense>
); 
export const ListDemandePage = (props) => (
    <Suspense fallback="">
        <ListDemandePageLazy {...props} />
    </Suspense>
);   
export const ViewDemandePage = (props) => (
    <Suspense fallback="">
        <ViewDemandePageLazy {...props} />
    </Suspense>
); 
export const EditDemandePage = (props) => (
    <Suspense fallback="">
        <EditDemandePageLazy {...props} />
    </Suspense>
); 
export const ListCivilitePage = (props) => (
    <Suspense fallback="">
        <ListCivilitePageLazy {...props} />
    </Suspense>
);   
export const ViewCivilitePage = (props) => (
    <Suspense fallback="">
        <ViewCivilitePageLazy {...props} />
    </Suspense>
); 
export const EditCivilitePage = (props) => (
    <Suspense fallback="">
        <EditCivilitePageLazy {...props} />
    </Suspense>
); 
export const ListTypeDemandeurPage = (props) => (
    <Suspense fallback="">
        <ListTypeDemandeurPageLazy {...props} />
    </Suspense>
);   
export const ViewTypeDemandeurPage = (props) => (
    <Suspense fallback="">
        <ViewTypeDemandeurPageLazy {...props} />
    </Suspense>
); 
export const EditTypeDemandeurPage = (props) => (
    <Suspense fallback="">
        <EditTypeDemandeurPageLazy {...props} />
    </Suspense>
); 
export const ListResultatDemandePage = (props) => (
    <Suspense fallback="">
        <ListResultatDemandePageLazy {...props} />
    </Suspense>
);   
export const ViewResultatDemandePage = (props) => (
    <Suspense fallback="">
        <ViewResultatDemandePageLazy {...props} />
    </Suspense>
); 
export const EditResultatDemandePage = (props) => (
    <Suspense fallback="">
        <EditResultatDemandePageLazy {...props} />
    </Suspense>
); 
export const ListNatureSinistrePage = (props) => (
    <Suspense fallback="">
        <ListNatureSinistrePageLazy {...props} />
    </Suspense>
);   
export const ViewNatureSinistrePage = (props) => (
    <Suspense fallback="">
        <ViewNatureSinistrePageLazy {...props} />
    </Suspense>
); 
export const EditNatureSinistrePage = (props) => (
    <Suspense fallback="">
        <EditNatureSinistrePageLazy {...props} />
    </Suspense>
); 
export const ListDemandeurPage = (props) => (
    <Suspense fallback="">
        <ListDemandeurPageLazy {...props} />
    </Suspense>
);   
export const ViewDemandeurPage = (props) => (
    <Suspense fallback="">
        <ViewDemandeurPageLazy {...props} />
    </Suspense>
); 
export const EditDemandeurPage = (props) => (
    <Suspense fallback="">
        <EditDemandeurPageLazy {...props} />
    </Suspense>
); 
export const ListActualitePage = (props) => (
    <Suspense fallback="">
        <ListActualitePageLazy {...props} />
    </Suspense>
);   
export const ViewActualitePage = (props) => (
    <Suspense fallback="">
        <ViewActualitePageLazy {...props} />
    </Suspense>
); 
export const EditActualitePage = (props) => (
    <Suspense fallback="">
        <EditActualitePageLazy {...props} />
    </Suspense>
); 
export const ListVillePage = (props) => (
    <Suspense fallback="">
        <ListVillePageLazy {...props} />
    </Suspense>
);   
export const ViewVillePage = (props) => (
    <Suspense fallback="">
        <ViewVillePageLazy {...props} />
    </Suspense>
); 
export const EditVillePage = (props) => (
    <Suspense fallback="">
        <EditVillePageLazy {...props} />
    </Suspense>
); 

const AdminRoutes = () => {
    const map=[];
    map.push(<Route key="100" index element={<AdminHomePage />} />);
    map.push(<Route key="101" path="/admin/user/list" element={<ListUserPage />} />);
    map.push(<Route key="102" path="/admin/user/edit" element={<EditUserPage />} />);
    map.push(<Route key="103" path="/admin/user/view/:userId" element={<ViewUserPage />} />);
    map.push(<Route key="104" path="/admin/user/edit/:userId" element={<EditUserPage />} />);
    map.push(<Route key="105" path="/admin/alerte/list" element={<ListAlertePage />} />);
    map.push(<Route key="106" path="/admin/alerte/edit" element={<EditAlertePage />} />);
    map.push(<Route key="107" path="/admin/alerte/view/:alerteId" element={<ViewAlertePage />} />);
    map.push(<Route key="108" path="/admin/alerte/edit/:alerteId" element={<EditAlertePage />} />);
    map.push(<Route key="109" path="/admin/media/list" element={<ListMediaPage />} />);
    map.push(<Route key="110" path="/admin/media/edit" element={<EditMediaPage />} />);
    map.push(<Route key="111" path="/admin/media/view/:mediaId" element={<ViewMediaPage />} />);
    map.push(<Route key="112" path="/admin/media/edit/:mediaId" element={<EditMediaPage />} />);
    map.push(<Route key="113" path="/admin/etatDemande/list" element={<ListEtatDemandePage />} />);
    map.push(<Route key="114" path="/admin/etatDemande/edit" element={<EditEtatDemandePage />} />);
    map.push(<Route key="115" path="/admin/etatDemande/view/:etatDemandeId" element={<ViewEtatDemandePage />} />);
    map.push(<Route key="116" path="/admin/etatDemande/edit/:etatDemandeId" element={<EditEtatDemandePage />} />);
    map.push(<Route key="117" path="/admin/objetLitige/list" element={<ListObjetLitigePage />} />);
    map.push(<Route key="118" path="/admin/objetLitige/edit" element={<EditObjetLitigePage />} />);
    map.push(<Route key="119" path="/admin/objetLitige/view/:objetLitigeId" element={<ViewObjetLitigePage />} />);
    map.push(<Route key="120" path="/admin/objetLitige/edit/:objetLitigeId" element={<EditObjetLitigePage />} />);
    map.push(<Route key="121" path="/admin/natureLitige/list" element={<ListNatureLitigePage />} />);
    map.push(<Route key="122" path="/admin/natureLitige/edit" element={<EditNatureLitigePage />} />);
    map.push(<Route key="123" path="/admin/natureLitige/view/:natureLitigeId" element={<ViewNatureLitigePage />} />);
    map.push(<Route key="124" path="/admin/natureLitige/edit/:natureLitigeId" element={<EditNatureLitigePage />} />);
    map.push(<Route key="125" path="/admin/compagnie/list" element={<ListCompagniePage />} />);
    map.push(<Route key="126" path="/admin/compagnie/edit" element={<EditCompagniePage />} />);
    map.push(<Route key="127" path="/admin/compagnie/view/:compagnieId" element={<ViewCompagniePage />} />);
    map.push(<Route key="128" path="/admin/compagnie/edit/:compagnieId" element={<EditCompagniePage />} />);
    map.push(<Route key="129" path="/admin/branche/list" element={<ListBranchePage />} />);
    map.push(<Route key="130" path="/admin/branche/edit" element={<EditBranchePage />} />);
    map.push(<Route key="131" path="/admin/branche/view/:brancheId" element={<ViewBranchePage />} />);
    map.push(<Route key="132" path="/admin/branche/edit/:brancheId" element={<EditBranchePage />} />);
    map.push(<Route key="133" path="/admin/relance/list" element={<ListRelancePage />} />);
    map.push(<Route key="134" path="/admin/relance/edit" element={<EditRelancePage />} />);
    map.push(<Route key="135" path="/admin/relance/view/:relanceId" element={<ViewRelancePage />} />);
    map.push(<Route key="136" path="/admin/relance/edit/:relanceId" element={<EditRelancePage />} />);
    map.push(<Route key="137" path="/admin/demande/list" element={<ListDemandePage />} />);
    map.push(<Route key="145" path="/admin/civilite/list" element={<ListCivilitePage />} />);
    map.push(<Route key="146" path="/admin/civilite/edit" element={<EditCivilitePage />} />);
    map.push(<Route key="147" path="/admin/civilite/view/:civiliteId" element={<ViewCivilitePage />} />);
    map.push(<Route key="148" path="/admin/civilite/edit/:civiliteId" element={<EditCivilitePage />} />);
    map.push(<Route key="149" path="/admin/typeDemandeur/list" element={<ListTypeDemandeurPage />} />);
    map.push(<Route key="150" path="/admin/typeDemandeur/edit" element={<EditTypeDemandeurPage />} />);
    map.push(<Route key="151" path="/admin/typeDemandeur/view/:typeDemandeurId" element={<ViewTypeDemandeurPage />} />);
    map.push(<Route key="152" path="/admin/typeDemandeur/edit/:typeDemandeurId" element={<EditTypeDemandeurPage />} />);
    map.push(<Route key="153" path="/admin/resultatDemande/list" element={<ListResultatDemandePage />} />);
    map.push(<Route key="154" path="/admin/resultatDemande/edit" element={<EditResultatDemandePage />} />);
    map.push(<Route key="155" path="/admin/resultatDemande/view/:resultatDemandeId" element={<ViewResultatDemandePage />} />);
    map.push(<Route key="156" path="/admin/resultatDemande/edit/:resultatDemandeId" element={<EditResultatDemandePage />} />);
    map.push(<Route key="157" path="/admin/natureSinistre/list" element={<ListNatureSinistrePage />} />);
    map.push(<Route key="158" path="/admin/natureSinistre/edit" element={<EditNatureSinistrePage />} />);
    map.push(<Route key="159" path="/admin/natureSinistre/view/:natureSinistreId" element={<ViewNatureSinistrePage />} />);
    map.push(<Route key="160" path="/admin/natureSinistre/edit/:natureSinistreId" element={<EditNatureSinistrePage />} />);
    map.push(<Route key="161" path="/admin/demandeur/list" element={<ListDemandeurPage />} />);
    map.push(<Route key="162" path="/admin/demandeur/edit" element={<EditDemandeurPage />} />);
    map.push(<Route key="163" path="/admin/demandeur/view/:demandeurId" element={<ViewDemandeurPage />} />);
    map.push(<Route key="164" path="/admin/demandeur/edit/:demandeurId" element={<EditDemandeurPage />} />);
    map.push(<Route key="165" path="/admin/actualite/list" element={<ListActualitePage />} />);
    map.push(<Route key="166" path="/admin/actualite/edit" element={<EditActualitePage />} />);
    map.push(<Route key="167" path="/admin/actualite/view/:actualiteId" element={<ViewActualitePage />} />);
    map.push(<Route key="168" path="/admin/actualite/edit/:actualiteId" element={<EditActualitePage />} />);
    map.push(<Route key="169" path="/admin/ville/list" element={<ListVillePage />} />);
    map.push(<Route key="170" path="/admin/ville/edit" element={<EditVillePage />} />);
    map.push(<Route key="171" path="/admin/ville/view/:villeId" element={<ViewVillePage />} />);
    map.push(<Route key="172" path="/admin/ville/edit/:villeId" element={<EditVillePage />} />);
    map.push(<Route key="182" path="/admin/demande/edit" element={<EditDemandePage />} />);
    map.push(<Route key="183" path="/admin/demande/view/:demandeId" element={<ViewDemandePage />} />);
    map.push(<Route key="184" path="/admin/demande/edit/:demandeId" element={<EditDemandePage />} />);
    return map;
};
export default AdminRoutes;