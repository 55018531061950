import { useAppSelector } from 'config/store.config';
import { Navigate, useLocation } from 'react-router';
import SessionStorage from 'util/session.storage';


const PrivateRoute = ({children}) => {
    const initialPage = useAppSelector((state) => state.authState.initialPage);
    const location = useLocation();
    const auth = ()=>{
        const u = SessionStorage.getUser();
        if (u && u.token) {
            return true;
        }else{
            return false;
        }
    };
    if (!auth() && location.pathname!=='/login') {
        if(initialPage==='/'){
            return <Navigate to="/login" replace />;
        }
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};
export default PrivateRoute;
