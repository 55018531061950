import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { FolderOutlined, HomeOutlined, MinusOutlined } from '@ant-design/icons';
import labels from 'util/labelMapping';

const AssuranceMenu = ({ initialPath }) => {
    const items = [
        { key: 'home', icon: <HomeOutlined />, label: <Link to="/">Home</Link> },
        { key: 'demandeList', icon: <MinusOutlined />, label: <Link to="/demande/list">{labels.get('demande.liste')}</Link> },
        {
            key: 'parametrage',
            icon: <FolderOutlined />,
            label: labels.get('parametrage'),
            children: [ 
                { key: 'compagnieEdit', icon: <MinusOutlined />, label: <Link to="/compagnie/edit">{labels.get('compagnie.info')}</Link> }, 
                { key: 'userList', icon: <MinusOutlined />, label: <Link to="/user/list">{labels.get('user.liste')}</Link> } 
            ]
        }
    ];

    return <Menu items={items} mode="inline" defaultSelectedKeys={[ initialPath ]} />;
};

export default AssuranceMenu;
