import { Page } from 'proxy/commun/representation/Page';
import { Pageable } from 'proxy/commun/representation/Pageable';
import { IPagination } from './pagination.modele';

const NOMBRE_LIGNE_PAR_PAGE_PAR_DEFAUT = 10; 
const creerPagination = function(page:Page<any>): IPagination {
    const pagination = {} as IPagination;
    pagination.nombreTotalDeLigne = page.totalElements;
    pagination.nombreLigneParPage = page.size?page.size:NOMBRE_LIGNE_PAR_PAGE_PAR_DEFAUT;
    pagination.pageCourante = page.number+1;
    return pagination;
};



const creerPageable = function(pagination:IPagination): Pageable {
    const pageable = {} as Pageable;

    pageable.page =  (pagination?.pageCourante || 1) - 1;
    pageable.size= pagination.nombreLigneParPage?pagination.nombreLigneParPage:NOMBRE_LIGNE_PAR_PAGE_PAR_DEFAUT;

    return pageable;
};


const PaginationMapper = {
    creerPagination,
    creerPageable
};
export default PaginationMapper;


/*    
    const params = new URLSearchParams();
    params.append('page', ((pagination?.pageCourante || 1) - 1).toFixed(0));
    params.append('size', pagination?.nombreLigneParPage);
    */