import axios from 'axios';
import { API_CALL_TIMEOUT, API_URL } from 'config/constants.config';
import QueryString from 'qs';
import SessionStorage from 'util/session.storage';

axios.defaults.timeout = API_CALL_TIMEOUT;
axios.defaults.baseURL = API_URL;
axios.defaults.paramsSerializer = params => {
    return QueryString.stringify(params, {arrayFormat: 'indices'});
};

export const JSON_HEADER ={ headers: { 'Content-Type': 'application/json' } };

const initAxios = () => {
    const onRequestSuccess = (config) => {
        const token = SessionStorage.getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };

    const onResponseSuccess = (response) => response;
    const onResponseError = (error) => {
        return Promise.reject(error);
    };
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default initAxios;
