import _ from 'lodash';
import { ISuccessMessage } from 'core/session/session.modele';

const map = {
    'user.getAccount': _.template('Connexion réussie'),
    'user.update': _.template('User <%= libelle %> a été modifié avec succès'),
    'user.create': _.template('User <%= libelle %> a été créé avec succès'),
    'user.delete': _.template('User <%= libelle %> a été supprimé avec succès'),
    'etatDemandeHist.update': _.template('Etat Demande  <%= libelle %> a été modifié avec succès'),
    'etatDemandeHist.create': _.template('l\'etat de la Demande a été modifié avec succès'),
    'etatDemandeHist.delete': _.template('Etat Demande  <%= libelle %> a été supprimé avec succès'),
    'alerte.update': _.template('Alerte <%= libelle %> a été modifié avec succès'),
    'alerte.create': _.template('Alerte <%= libelle %> a été créé avec succès'),
    'alerte.delete': _.template('Alerte <%= libelle %> a été supprimé avec succès'),
    'media.update': _.template('Media <%= libelle %> a été modifié avec succès'),
    'media.create': _.template('Media <%= libelle %> a été créé avec succès'),
    'media.delete': _.template('Media <%= libelle %> a été supprimé avec succès'),
    'etatDemande.update': _.template('Etat Demande <%= libelle %> a été modifié avec succès'),
    'etatDemande.create': _.template('Etat Demande <%= libelle %> a été créé avec succès'),
    'etatDemande.delete': _.template('Etat Demande <%= libelle %> a été supprimé avec succès'),
    'objetLitige.update': _.template('Objet Litige <%= libelle %> a été modifié avec succès'),
    'objetLitige.create': _.template('Objet Litige <%= libelle %> a été créé avec succès'),
    'objetLitige.delete': _.template('Objet Litige <%= libelle %> a été supprimé avec succès'),
    'natureLitige.update': _.template('Nature Litige <%= libelle %> a été modifié avec succès'),
    'natureLitige.create': _.template('Nature Litige <%= libelle %> a été créé avec succès'),
    'natureLitige.delete': _.template('Nature Litige <%= libelle %> a été supprimé avec succès'),
    'compagnie.update': _.template('Compagnie <%= libelle %> a été modifié avec succès'),
    'compagnie.create': _.template('Compagnie <%= libelle %> a été créé avec succès'),
    'compagnie.delete': _.template('Compagnie <%= libelle %> a été supprimé avec succès'),
    'branche.update': _.template('Branche <%= libelle %> a été modifié avec succès'),
    'branche.create': _.template('Branche <%= libelle %> a été créé avec succès'),
    'branche.delete': _.template('Branche <%= libelle %> a été supprimé avec succès'),
    'relance.update': _.template('Relance <%= libelle %> a été modifié avec succès'),
    'relance.create': _.template('Relance <%= libelle %> a été créé avec succès'),
    'relance.delete': _.template('Relance <%= libelle %> a été supprimé avec succès'),
    'demande.update': _.template('Demande <%= libelle %> a été modifié avec succès'),
    'demande.create': _.template('Demande <%= libelle %> a été créé avec succès'),
    'demande.delete': _.template('Demande <%= libelle %> a été supprimé avec succès'),
    'civilite.update': _.template('Civilite <%= libelle %> a été modifié avec succès'),
    'civilite.create': _.template('Civilite <%= libelle %> a été créé avec succès'),
    'civilite.delete': _.template('Civilite <%= libelle %> a été supprimé avec succès'),
    'typeDemandeur.update': _.template('Type Demandeur <%= libelle %> a été modifié avec succès'),
    'typeDemandeur.create': _.template('Type Demandeur <%= libelle %> a été créé avec succès'),
    'typeDemandeur.delete': _.template('Type Demandeur <%= libelle %> a été supprimé avec succès'),
    'resultatDemande.update': _.template('Resultat Demande <%= libelle %> a été modifié avec succès'),
    'resultatDemande.create': _.template('Resultat Demande <%= libelle %> a été créé avec succès'),
    'resultatDemande.delete': _.template('Resultat Demande <%= libelle %> a été supprimé avec succès'),
    'natureSinistre.update': _.template('Nature Sinistre <%= libelle %> a été modifié avec succès'),
    'natureSinistre.create': _.template('Nature Sinistre <%= libelle %> a été créé avec succès'),
    'natureSinistre.delete': _.template('Nature Sinistre <%= libelle %> a été supprimé avec succès'),
    'demandeur.update': _.template('Demandeur <%= libelle %> a été modifié avec succès'),
    'demandeur.create': _.template('Demandeur <%= libelle %> a été créé avec succès'),
    'demandeur.delete': _.template('Demandeur <%= libelle %> a été supprimé avec succès'),
    'actualite.update': _.template('Actualite <%= libelle %> a été modifié avec succès'),
    'actualite.create': _.template('Actualite <%= libelle %> a été créé avec succès'),
    'actualite.delete': _.template('Actualite <%= libelle %> a été supprimé avec succès'),
    'ville.update': _.template('Ville <%= libelle %> a été modifié avec succès'),
    'ville.create': _.template('Ville <%= libelle %> a été créé avec succès'),
    'ville.delete': _.template('Ville <%= libelle %> a été supprimé avec succès'),
};

const get = (successMessage: ISuccessMessage): string => {
    if (_.isEmpty(successMessage) || _.isNull(successMessage) || _.isUndefined(successMessage)) {
        return null;
    }
    const template = map[successMessage.type + '.' + successMessage.key];
    if (_.isNull(template) || _.isUndefined(template)) {
        return null;
    }
    return template(successMessage);
};

const messages = {
    get
};

export default messages;