import React, { Suspense } from 'react';
import { Route } from 'react-router';


const MediateurHomePageLazy = React.lazy(() => import('domaines/mediateur/home'));
const ListDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.mediateur.liste'));
const ViewDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.view'));
const EditDemandePageLazy = React.lazy(() => import('modules/demande/demande.ui.demandeur.edit'));
  
export const MediateurHomePage = (props) => (
    <Suspense fallback="">
        <MediateurHomePageLazy {...props} />
    </Suspense>
);

export const ListDemandePage = (props) => (
    <Suspense fallback="">
        <ListDemandePageLazy {...props} />
    </Suspense>
);  
export const ViewDemandePage = (props) => (
    <Suspense fallback="">
        <ViewDemandePageLazy {...props} />
    </Suspense>
); 
export const EditDemandePage = (props) => (
    <Suspense fallback="">
        <EditDemandePageLazy {...props} />
    </Suspense>
); 

const MediateurRoutes = () => {
    const map=[];
    map.push(<Route key="200" path="/"  element={<MediateurHomePage />} />);
    map.push(<Route key="300"  path="/demande/list" element={<ListDemandePage />} />);
    map.push(<Route key="203" path="/demande/view/:demandeId" element={<ViewDemandePage />} />);
    map.push(<Route key="204" path="/demande/edit/:demandeId" element={<EditDemandePage />} />);
    return map;
};
export default MediateurRoutes;
