import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { HomeOutlined, MinusOutlined } from '@ant-design/icons';
import labels from 'util/labelMapping';

const MediateurMenu = ({ initialPath }) => {

    const items = [
        {  key:'home', icon:<HomeOutlined />, label:<Link to="/app">Home</Link> },
        {  key:'demandeList', icon:<MinusOutlined />, label: <Link to="/demande/list">{labels.get('demande.liste')}</Link> },
    ];

    return (
        <Menu items={items} mode="inline" defaultSelectedKeys={[initialPath]}/>
    );
  
};

export default MediateurMenu;
