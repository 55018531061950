import { Provider as StoreProvider } from 'react-redux';
import getStore from 'config/store.config';
import initAxios from 'config/axios.config';
import AppRoutes from 'routes';
import ErrorBoundary from 'routes/errorBoundary';
import './assets/styles/base.less';

const App = () => {
    const store = getStore();
    initAxios();

    return (
        <StoreProvider store={store}>
            <ErrorBoundary>
                <AppRoutes />
            </ErrorBoundary>
        </StoreProvider>
    );
};
export default App;
