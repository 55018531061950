import styled from 'styled-components';

export const LoginFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
    margin-top:-100px;
    .ant-input-prefix {
        color: gray;
    }
    .ant-input-affix-wrapper-disabled {
        opacity: 0.8;
    }
`;

export const FrameContent = styled.div`
    padding: 25px;
    min-width: 400px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
`;

export const LogoHolder = styled.div`
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
    img{
        width:200px;
        margin:20px 0 40px 0;
    }
`;
